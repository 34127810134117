import React from 'react';
import { Link } from 'react-router-dom';
import {
    Input, Textarea, Typography, WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { serializeRecord } from '@lovelyvoyager/services/dist/utils';
import { CreateSupportDto } from '@lovelyvoyager/types/dist/support/create-support.dto';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import successImage from 'src/images/congrats.png';
import image from 'src/images/support.png';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import styles from 'src/pages/index.module.css';

export const SupportRequest: React.FC<DefaultPageProps> = ({ t }) => {
    const { support } = useApi();

    const {
        createError, createFetch, createStatusOk, createLoading,
    } = useApiMethod(
        support.create,
        {},
    );

    if (createStatusOk) {
        return (
            <WelcomeContainer image={ successImage }>
                <Head title={ t('afterSupport.title') } subtitle={ t('afterSupport.text') } />
                <br />
                <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                    <Link to="/auth/questrooms">{ t('afterSupport.footerLink1') }</Link>
                    { t('afterSupport.footerText') }

                    <Link to="/auth/players">{ t('afterSupport.footerLink2') }</Link>
                </Typography.Text>
            </WelcomeContainer>
        );
    }

    return (
        <WelcomeContainer image={ image }>
            <Head title={ t('support.title') } subtitle={ t('support.description') } />

            <AuthForm
                initialValues={ serializeRecord(CreateSupportDto, {}) as unknown as CreateSupportDto }
                onSubmit={ (data) => createFetch({ data }) }
                buttonText={ t('support.button') }
                error={ createError && createError.message }
                loading={ createLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            label={ t('support.emailLabel') }
                            required={ true }
                            dataTestId="email"
                        />
                        <Input
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            label={ t('support.phoneLabel') }
                            dataTestId="phone"
                        />
                        <Textarea
                            value={ values.text }
                            onChange={ handleChange('text') }
                            error={ touched.text && errors.text }
                            label={ t('support.problemLabel') }
                            required={ true }
                            dataTestId="question"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                <Link to="/auth/girls">{ t('support.textLink1') }</Link>
                { t('support.text1') } <Link to="/auth/visitors">{ t('support.textLink2') }</Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
