export type MenuElement = {
    path: string;
    element: React.ReactNode;
};
export const convertRoutes = (routes: MenuElement[]) =>
    routes.map(({ path, element, ...restProps }) => ({
        path,
        element,
        ...restProps,
    }));
