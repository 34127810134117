import React from 'react';
import { Typography } from '@alphakits/ui/dist';

import styles from './index.module.css';

type HeadProps = {
    title: string | React.ReactNode;
    icon?: string;
    subtitle?: string;
    label?: string;
};

export const Head: React.FC<HeadProps> = ({
    title, subtitle, label, icon,
}) => (
    <React.Fragment>
        { label && (
            <Typography.Text className={ styles.label } view="title" color="tertiary">
                { label }
            </Typography.Text>
        ) }
        <Typography.Title
            weight="regular"
            tag="h1"
            className={ styles.title }
            view="small"
            color="primary"
        >
            { title }

            { icon && (
                <span role="img" aria-label="holla">
                    { icon }
                </span>
            ) }
        </Typography.Title>

        { subtitle && (
            <Typography.Text className={ styles.description } view="title" color="tertiary">
                { subtitle }
            </Typography.Text>
        ) }
    </React.Fragment>
);
