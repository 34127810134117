import React, { PropsWithChildren } from 'react';
import {
    Button, FlexColumns, ListAllert, Padding, TFunction,
} from '@alphakits/ui/dist';
import { validateByDto } from '@lovelyvoyager/services/dist/utils';
import { Formik, FormikProps, FormikValues } from 'formik';

import styles from 'src/pages/index.module.css';

type Props<T> = {
    initialValues: T;
    onSubmit: (values: FormikProps<T>['values']) => void;
    loading: boolean;
    error?: string;
    buttonText: string;
    children: (props: FormikProps<FormikValues & T>) => React.ReactNode;
    t: TFunction;
};

export function AuthForm<T>({
    initialValues,
    buttonText,
    loading,
    onSubmit,
    t,
    children,
    error,
}: PropsWithChildren<Props<T>>) {
    return (
        <div className={ styles.content }>
            <Formik initialValues={ initialValues } validate={ validateByDto(t) } onSubmit={ onSubmit }>
                { (props) => (
                    <React.Fragment>
                        <FlexColumns columns={ 1 } gr={ 20 } gc={ 0 }>
                            { children(props) }
                        </FlexColumns>

                        { error && (
                            <React.Fragment>
                                <Padding padding="16px 0" />
                                <ListAllert text={ error } view="negative" />
                            </React.Fragment>
                        ) }

                        <Button
                            onClick={ () => props.handleSubmit() }
                            className={ styles.button }
                            block={ true }
                            loading={ loading }
                            view="primary"
                            size="s"
                            dataTestId="support"
                        >
                            { buttonText }
                        </Button>
                    </React.Fragment>
                ) }
            </Formik>
        </div>
    );
}
