export const TRANSLATIONS_EN = {
    notifications: {
        supportErrorTitle: 'Something went wrong..',
    },
    validations: {
        password: 'Minimum 8 characters',
        email: 'Enter Email',
        phone: 'Enter your phone',
        required: 'Required field',
        repeatPassword: 'Passwords must match',
    },
    afterRegistration: {
        title: "We'll be in touch soon!",
        text: 'We have received your application, our manager will check the information, and will contact you to clarify other data. It will take no more than 2 hours during working hours from 10:00 to 20:00.',
    },

    afterSupport: {
        title: "We'll be in touch soon!",
        text: 'Everything is super! We have received your message and someone from our team will contact you shortly. In the meantime, we have sent the data on your application to the specified email.',
        footerLink1: 'Log in for quests',
        footerText: 'or',
        footerLink2: 'Customer Login',
    },

    authVisitors: {
        label: 'Login for players',
        title: 'Can we solve a couple of riddles?',
        emailLabel: 'Phone / Email',
        passwordLabel: 'Password',
        checkbox: "Another's computer",
        removeLink: 'Forgot your password?',
        button: 'Sign in to your account',
        text1: "Don't have an account yet? You can",
        textLink1: 'Register now',
        text2: '. If you are the owner or employee of the quest, then go to',
        textLink2: 'Quest authorization',
    },

    authGirls: {
        label: 'Quest authorization',
        title: 'We are glad that you are back ',
        emailLabel: 'Phone / Email',
        passwordLabel: 'Password',
        checkbox: "another's computer",
        removeLink: 'Forgot your password?',
        button: 'Sign in to your account',
        text: "Don't have an account yet?",
        textLink: 'Register now',
    },

    passwordChange: {
        label: 'For quests',
        title: 'Password recovery',
        description:
            'Set a new password. The minimum length is 8 characters, at least one letter is required.',
        emailLabel: 'New Password',
        passwordLabel: 'Repeat password',
        button: 'Sign in to your account',
        text1: "Can't recover your password?",
        textLink1: 'Contact technical support',
        text2: 'Already have an account?',
        textLink2: 'Log in here',
    },

    passwordRestore: {
        label: 'For quests',
        title: 'Password recovery',
        description: 'Enter the email that was specified when registering an account',
        emailLabel: 'Email',
        button: 'Restore password',
        text1: "Can't recover your password?",
        textLink1: 'Contact technical support',
        text2: 'Already have an account?',
        textLink2: 'Log in here',
    },

    registrationVisitors: {
        label: 'Registration for visitors',
        title: 'Welcome to the community of quest lovers ',
        nameLabel: 'Name',
        phoneLabel: 'Phone',
        emailLabel: 'Email',
        button: 'Register now',
        text1: 'By creating an account, you agree to ',
        textLink1: 'Personal data processing policy',
        text2: ', accept ',
        textLink2: 'Terms of Service',
        text3: ' and',
        textLink3: 'Terms of use',
        text4: 'Already have an account?',
        textLink4: 'Log in here',
    },

    registrationGirls: {
        label: 'Registration for saloons and private masters',
        title: 'Welcome to the Voyager community',
        nameLabel: 'Name',
        phoneLabel: 'Phone',
        emailLabel: 'Email',
        linkLabel: 'Link to website or VK',
        button: 'Register now',
        text1: 'By creating an account, you agree to ',
        textLink1: 'Privacy Policy',
        text2: ', accept ',
        textLink2: 'Terms of use',
        text3: ' and',
        textLink3: 'Offer agreement',
        text4: 'Already have an account?',
        textLink4: 'Log in here',
    },

    acceptInvite: {
        label: 'Data confirmation',
        title: 'Complete Voyager Community Registration',
        description:
            'Set a password. Minimum length is 8 characters, at least one letter is required.',
        button: 'Complete registration',
        emailLabel: 'Create a password',
        passwordLabel: 'Repeat Password',
    },

    support: {
        title: 'Contacting technical support',
        description:
            'Our technical support service works 24/7. We will try to resolve the issue as soon as possible.',
        emailLabel: 'Email for feedback',
        phoneLabel: 'Phone (we will answer faster)',
        problemLabel: 'Describe your problem',
        button: 'Send',
        textLink1: 'Log in for quests',
        text1: ' or',
        textLink2: 'Customer Login',
    },

    error: {
        oops: 'Ooops!...',
        description:
            'The password change link is not valid. You can get a new link by clicking on the button below.',
        button: 'Get new link',
    },
    notFound: {
        oops: 'Ooops!...',
        returnToMain: 'Return to main',
        noPage: "It looks like this page doesn't exist",
    },
};
