import React from 'react';
import { TFunction } from 'react-i18next';

import {
    LoginAdmin,
    LoginGirl,
    RegistrationGirls,
    SupportRequest,
    UserChangePassword,
    UserRecoverPassword,
} from '../pages';

export type DefaultPageProps = {
    t: TFunction;
    lang?: string;
};

export const routes = ({ t, lang }: DefaultPageProps) => [
    {
        path: '/auth/girls',
        element: <LoginGirl t={ t } lang={ lang } />,
    },
    {
        path: '/auth/admins',
        element: <LoginAdmin t={ t } lang={ lang } />,
    },
    {
        path: '/registration/girls',
        element: <RegistrationGirls t={ t } lang={ lang } />,
    },
    {
        path: '/support/question',
        element: <SupportRequest t={ t } lang={ lang } />,
    },
    {
        path: '/password/recover',
        element: <UserRecoverPassword t={ t } lang={ lang } />,
    },
    {
        path: '/password/change',
        element: <UserChangePassword t={ t } lang={ lang } />,
    },
];
