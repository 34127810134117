import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Checkbox, Input, PageLoading, Typography, WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { serializeRecord } from '@lovelyvoyager/services/dist/utils';
import { LoginUserDto } from '@lovelyvoyager/types/dist/user/login-user.dto';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import image from 'src/images/person_hi.png';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import styles from 'src/pages/index.module.css';

export const LoginGirl: React.FC<DefaultPageProps> = ({ t }) => {
    const { users } = useApi();

    const successCallback = () => window.location.assign(process.env.REACT_APP_APP_DOMAIN);

    const { loginFetch, loginLoading, loginError } = useApiMethod(users.login, { successCallback });

    const { meLoading, meFetch } = useApiMethod(users.me, { successCallback });

    useEffect(() => {
        meFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (meLoading) return <PageLoading />;

    return (
        <WelcomeContainer image={ image }>
            <Head title={ t('authGirls.title') } label={ t('authGirls.label') } icon="👋" />

            <AuthForm
                initialValues={ serializeRecord(LoginUserDto, {}) as unknown as LoginUserDto }
                onSubmit={ (data) => loginFetch({ data }) }
                buttonText={ t('authGirls.button') }
                error={ loginError && loginError.message }
                loading={ loginLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors, setFieldValue,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && (errors.email as string) }
                            label={ t('authGirls.emailLabel') }
                            required={ true }
                            autoFocus={ true }
                            dataTestId="email"
                        />
                        <Input
                            value={ values.password }
                            type="password"
                            onChange={ handleChange('password') }
                            error={ touched.password && (errors.password as string) }
                            label={ t('authGirls.passwordLabel') }
                            required={ true }
                            dataTestId="password"
                        />

                        <div className={ styles.checkbox_wrap }>
                            <Checkbox
                                checked={ values.remember }
                                onChange={ (e, { checked }: { checked: boolean }) =>
                                    setFieldValue('remember', checked) }
                                label={ t('authGirls.checkbox') }
                                dataTestId="remember"
                            />
                            <Link className={ styles.forgot_link } to="/password/recover">
                                { t('authGirls.removeLink') }
                            </Link>
                        </div>
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                { t('authGirls.text') }{ ' ' }
                <Link className={ styles.link_primary } to="/registration/girls">
                    { t('authGirls.textLink') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
