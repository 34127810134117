export const TRANSLATIONS_RU = {
    notifications: {
        supportErrorTitle: 'Что-то пошло не так..',
    },
    validations: {
        password: 'Минимум 8 символов',
        email: 'Введите Email',
        phone: 'Введите телефон',
        required: 'Обязательное поле',
        repeatPassword: 'Пароли должны совпадать',
    },
    afterRegistration: {
        title: 'Скоро выйдем на связь!',
        text: 'Мы получили Вашу заявку, наш менеджер проверит информацию, и свяжется с Вами для уточнения прочих данных. Это займет не более 2-х часов в рабочие с 10:00 до 20:00.',
    },

    afterSupport: {
        title: 'Скоро выйдем на связь!',
        text: 'Все супер! Мы получили ваше сообщение и кто-нибудь из нашей команды скоро свяжется с вами. А пока мы отправили данные по вашей заявке на указанный email.',
        footerLink1: 'Вход для сотрудников',
        footerText: 'или',
        footerLink2: 'Вход для посетителей',
    },

    authVisitors: {
        label: 'Авторизация для посетителей',
        title: 'Готовы к приключчениям? ',
        emailLabel: 'Телефон / Email',
        passwordLabel: 'Пароль',
        checkbox: 'Чужой компьютер',
        removeLink: 'Забыли пароль?',
        button: 'Войти в аккаунт',
        text1: 'Еще нет аккаунта? Вы можете',
        textLink1: 'Зарегистрироваться',
        text2: '. Если Вы владелец салона или ваша анкета представлена на сайте, то перейдите на',
        textLink2: 'Авторизацию для салонов',
    },

    authGirls: {
        label: 'Авторизация для салонов и частных мастеров',
        title: 'Мы рады, что Вы вернулись ',
        emailLabel: 'Телефон / Email',
        passwordLabel: 'Пароль',
        checkbox: 'Чужой компьютер',
        removeLink: 'Забыли пароль?',
        button: 'Войти в аккаунт',
        text: 'Еще нет аккаунта?',
        textLink: 'Зарегистрироваться',
    },

    passwordChange: {
        label: 'Для салонов и частных мастеров',
        title: 'Восстановление пароля',
        description:
            'Установите новый пароль. Минимальная длинна 8 символов, обязательно использование хотя бы одной буквы.',
        emailLabel: 'Придумайте пароль',
        passwordLabel: 'Повторите пароль',
        button: 'Изменить пароль',
        text1: 'Не удается восстановить пароль?',
        textLink1: 'Свяжитесь с техподдержкой',
        text2: 'Уже есть аккаунт?',
        textLink2: 'Авторизируйтесь тут',
    },

    passwordRestore: {
        label: 'Для салонов и частных мастеров',
        title: 'Восстановление пароля',
        description: 'Укажите электронную почту, которая была указана при регистрации аккаунта',
        emailLabel: 'Email',
        button: 'Восстановить пароль',
        button2: 'Отправить повторно через',
        allert: 'Ссылка для восстановления пароля отправлена на',
        text1: 'Не удается восстановить пароль?',
        textLink1: 'Свяжитесь с техподдержкой',
        text2: 'Уже есть аккаунт?',
        textLink2: 'Авторизируйтесь тут',
    },

    registrationVisitors: {
        label: 'Регистрация для посетителей',
        title: 'Приветствуем тебя в сообществе любителей приключений',
        nameLabel: 'Имя',
        phoneLabel: 'Телефон',
        emailLabel: 'Email',
        button: 'Зарегистрироваться',
        text1: 'Создавая аккаунт, Вы соглашаетесь с',
        textLink1: 'Политикой обработки персональных данных',
        text2: ', принимаете',
        textLink2: 'Условия использования сервиса',
        text3: ' и',
        textLink3: 'Пользовательское соглашение',
        text4: 'Уже есть аккаунт?',
        textLink4: 'Авторизируйтесь тут',
    },

    registrationGirls: {
        label: 'Регистрация для салонов и частных мастеров',
        title: 'Добро пожаловать в сообщество Voyager',
        nameLabel: 'Имя',
        phoneLabel: 'Телефон',
        emailLabel: 'Email',
        linkLabel: 'Ссылка на сайт или ВК',
        button: 'Зарегистрироваться',
        text1: 'Создавая аккаунт, Вы соглашаетесь с ',
        textLink1: 'Политикой конфиденциальности',
        text2: ', принимаете ',
        textLink2: 'Пользовательское соглашение',
        text3: ' и',
        textLink3: 'Договор офферту',
        text4: 'Уже есть аккаунт?',
        textLink4: 'Авторизируйтесь тут',
    },

    acceptInvite: {
        label: 'Подтверждение данных',
        title: 'Завершите регистрацию в сообществе Voyager',
        description:
            'Установите пароль. Минимальная длинна 8 символов, обязательно использование хотя бы одной буквы.',
        button: 'Завершить регистрацию',
        emailLabel: 'Придумайте пароль',
        passwordLabel: 'Повторите пароль',
    },

    support: {
        title: 'Обращение в техподдержку',
        description:
            'Наша служба технической поддержки работает 24/7. Мы постараемся решить проблемму как можно скорее.',
        emailLabel: 'Email для обратной связи',
        phoneLabel: 'Телефон (ответим быстрее)',
        problemLabel: 'Опишите вашу проблему',
        button: 'Отправить',
        textLink1: 'Вход для салонов и мастеров',
        text1: ' или',
        textLink2: 'Вход для посетителей',
    },
    error: {
        oops: 'Ууупс!...',
        description:
            'Ссылка на изменение пароля не действительна. Вы можете получить новую ссылку, нажав на кнопку ниже.',
        button: 'Получить новую ссылку',
    },
    notFound: {
        oops: 'Ууупс!...',
        returnToMain: 'Вернуться на главную',
        noPage: 'Похоже, что такой страницы не существует',
    },
};
