export function getLinks(lang) {
    return {
        CLIENTS_CONDUCT: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/clients-conduct`,
        CLIENTS_MODERATION: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/clients-moderation`,
        CLIENTS_POLICY: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/clients-policy`,
        CLIENTS_REFUND: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/clients-refund`,

        QUESTROOMS_CONDUCT: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/questrooms-conduct`,
        QUESTROOMS_OFFER: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/questrooms-offer`,
        QUESTROOMS_POLICY: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/questrooms-policy`,

        ONLINE_BOOKING: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/online-booking`,
        COOKIES: `${process.env.REACT_APP_DOCUMENTS_DOMAIN}/${lang}/cookies`,
    };
}
