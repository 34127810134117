import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Input, PageLoading, Typography, WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { serializeRecord } from '@lovelyvoyager/services/dist/utils';
import { UpdateUserPasswordDto } from '@lovelyvoyager/types/dist/user/update-user-password.dto';
import { parse } from 'query-string';
import { Error } from 'src/components/error';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import image from 'src/images/password.png';
import errorImage from 'src/images/support.png';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import styles from 'src/pages/index.module.css';

type Type = 'recover' | 'initial';

export const UserChangePassword: React.FC<DefaultPageProps> = ({ t }) => {
    const navigate = useNavigate();
    const { token, type } = parse(useLocation().search) as { token: string; type: Type };

    const { users } = useApi();

    const { checkTokenLoading, checkTokenError, checkTokenFetch } = useApiMethod(
        users.checkToken,
        {},
    );

    const { updatePasswordLoading, updatePasswordFetch, updatePasswordError } = useApiMethod(
        users.updatePassword,
        {
            successCallback: () => window.location.assign(process.env.REACT_APP_APP_DOMAIN),
        },
    );

    useEffect(() => {
        if (!token) return;

        checkTokenFetch({ token });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (checkTokenError) {
        return (
            <Error
                image={ errorImage }
                title={ t('error.oops') }
                description={ t('error.description') }
                buttonText={ t('error.button') }
                onClick={ () => navigate('/password/recover') }
            />
        );
    }

    if (checkTokenLoading) return <PageLoading />;

    const label = type === 'recover' ? t('passwordChange.label') : t('acceptInvite.label');
    const subtitle =
        type === 'recover' ? t('passwordChange.description') : t('acceptInvite.description');
    const title = type === 'recover' ? t('passwordChange.title') : t('acceptInvite.title');
    const button = type === 'recover' ? t('passwordChange.button') : t('acceptInvite.button');

    return (
        <WelcomeContainer image={ image }>
            <Head label={ label } title={ title } subtitle={ subtitle } />

            <AuthForm
                initialValues={
                    serializeRecord(UpdateUserPasswordDto, {}) as unknown as UpdateUserPasswordDto
                }
                onSubmit={ (data) => updatePasswordFetch({ token, data }) }
                buttonText={ button }
                error={ updatePasswordError && updatePasswordError.message }
                loading={ updatePasswordLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.password }
                            type="password"
                            onChange={ handleChange('password') }
                            error={ touched.password && errors.password }
                            label={ t('passwordChange.emailLabel') }
                            required={ true }
                            dataTestId="password"
                        />
                        <Input
                            value={ values.repeatPassword }
                            type="password"
                            onChange={ handleChange('repeatPassword') }
                            error={ touched.repeatPassword && errors.repeatPassword }
                            label={ t('passwordChange.passwordLabel') }
                            required={ true }
                            dataTestId="repeatPasword"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                { t('passwordChange.text1') }{ ' ' }
                <Link to="/support/question">{ t('passwordChange.textLink1') }</Link> <br /> <br />
                { t('passwordChange.text2') }{ ' ' }
                <Link className={ styles.link_primary } to="/auth/girls">
                    { t('passwordChange.textLink2') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
