/* eslint-disable no-plusplus */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    FlexColumns,
    Input,
    ListAllert,
    Padding,
    Typography,
    WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { RecoverPasswordDto } from '@lovelyvoyager/types/dist/user/recover-password.dto';
import { Formik } from 'formik';
import { Head } from 'src/components/head';
import image from 'src/images/password.png';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import styles from 'src/pages/index.module.css';

const COUNTER_KEY = 'my-counter';
const EMAIL_KEY = 'current-email';

export const UserRecoverPassword: React.FC<DefaultPageProps> = ({ t }) => {
    const [startTimer, setStartTimer] = React.useState(false);
    const [sec, setSeconds] = React.useState(30);
    const { users } = useApi();

    const countDownTime = window.sessionStorage.getItem(COUNTER_KEY) || 30;
    const currentEmail = window.sessionStorage.getItem(EMAIL_KEY);

    const countDown = React.useCallback((i, email) => {
        window.sessionStorage.setItem(EMAIL_KEY, email); // set email to sessionStorage

        const timer = setInterval(() => {
            let seconds: string | number = parseInt((i % 60).toString(), 10);

            seconds = seconds < 10 ? `0${seconds}` : seconds;
            setSeconds(+seconds); // предаем наружу секунды

            // eslint-disable-next-line no-param-reassign
            if (i--) {
                window.sessionStorage.setItem(COUNTER_KEY, i);
            } else {
                window.sessionStorage.removeItem(COUNTER_KEY);
                window.sessionStorage.removeItem(EMAIL_KEY);
                clearInterval(timer);
                setStartTimer(false);
            }
        }, 1000);
    }, []);

    const { sendRecoverLinkFetch, sendRecoverLinkLoading, sendRecoverLinkError } = useApiMethod(
        users.sendRecoverLink,
        {
            successCallback: ({ data }) => {
                setStartTimer(true);
                countDown(countDownTime, data.email);
            },
        },
    );

    React.useEffect(() => {
        if (countDownTime < 30) {
            setStartTimer(true);
            countDown(countDownTime, currentEmail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WelcomeContainer image={ image }>
            <Head
                label={ t('passwordRestore.label') }
                title={ t('passwordRestore.title') }
                subtitle={ t('passwordRestore.description') }
            />

            <div className={ styles.content }>
                <Formik
                    initialValues={
                        serializeRecord(RecoverPasswordDto, {}) as unknown as RecoverPasswordDto
                    }
                    validate={ validateByDto(t) }
                    onSubmit={ ({ email }) => sendRecoverLinkFetch(email) }
                >
                    { ({
                        handleChange, handleSubmit, values, errors, touched,
                    }) => (
                        <React.Fragment>
                            <FlexColumns columns={ 1 } gr={ 20 } gc={ 0 }>
                                <Input
                                    value={ values.email }
                                    onChange={ handleChange('email') }
                                    error={ touched.email && errors.email }
                                    label={ t('passwordRestore.emailLabel') }
                                    required={ true }
                                    dataTestId="email"
                                />
                                { startTimer && (
                                    <ListAllert
                                        className={ styles.allert }
                                        text={ `${t('passwordRestore.allert')} ${values.email}` }
                                        dataTestId="passwordAlert"
                                    />
                                ) }
                            </FlexColumns>

                            { sendRecoverLinkError && (
                                <React.Fragment>
                                    <Padding padding="16px 0" />
                                    <ListAllert
                                        text={ sendRecoverLinkError.message }
                                        view="negative"
                                    />
                                </React.Fragment>
                            ) }

                            <Button
                                onClick={ () => handleSubmit() }
                                className={ styles.button }
                                block={ true }
                                disabled={ startTimer }
                                loading={ sendRecoverLinkLoading }
                                view="primary"
                                size="s"
                                dataTestId="passwordRestore"
                            >
                                { startTimer && sec < 30
                                    ? `${t('passwordRestore.button2')} 00:${
                                        sec < 10 ? `0${sec}` : sec
                                    }`
                                    : t('passwordRestore.button') }
                            </Button>
                        </React.Fragment>
                    ) }
                </Formik>
            </div>

            <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                { t('passwordRestore.text1') }{ ' ' }
                <Link to="/support/question">{ t('passwordRestore.textLink1') }</Link> <br /> <br />
                { t('passwordRestore.text2') }{ ' ' }
                <Link className={ styles.link_primary } to="/auth/girls">
                    { t('passwordRestore.textLink2') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
