import React from 'react';
import {
    Button, Padding, Typography, WelcomeContainer,
} from '@alphakits/ui/dist';

type Props = {
    image: string;
    title: string;
    description: string;
    buttonText: string;
    onClick: () => void;
};

export const Error: React.FC<Props> = ({
    image, onClick, title, description, buttonText,
}) => (
    <WelcomeContainer image={ image }>
        <Typography.Title weight="regular" tag="h1" view="small" color="primary">
            { title }
        </Typography.Title>

        <Typography.Text view="title" color="tertiary">
            { description }
        </Typography.Text>

        <Padding padding="16px 0" />

        <Button onClick={ onClick } size="s" view="primary" block={ true } dataTestId="recovery">
            { buttonText }
        </Button>
    </WelcomeContainer>
);
