import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { RootRouter } from './router';

export const App = () => (
    <BrowserRouter>
        <RootRouter />
    </BrowserRouter>
);
