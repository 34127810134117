import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { NotFound } from '@alphakits/ui/dist';
import i18next from 'i18next';
import { convertRoutes } from 'src/utils/convert-to-routes';

import { DefaultPageProps, routes } from './routes';

import '../locales/i18n';

const Routes = ({ t, lang }: DefaultPageProps) =>
    useRoutes([
        ...convertRoutes(routes({ t, lang })),
        {
            path: '*',
            element: (
                <NotFound
                    title={ t('notFound.oops') }
                    code="404"
                    buttonText={ t('notFound.returnToMain') }
                    subtitle={ t('notFound.noPage') }
                    href="/"
                />
            ),
        },
        {
            path: '/',
            element: <React.Fragment />,
        },
    ]);

export const RootRouter = () => {
    const { t } = useTranslation();
    const lang = i18next.language;

    const navigate = useNavigate();
    const location = useLocation();

    const isHomePath = location.pathname === '/';

    useEffect(() => {
        if (isHomePath) {
            navigate('/auth/girls');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHomePath]);

    return <Routes t={ t } lang={ lang } />;
};
