export const TRANSLATIONS_DE = {
    notifications: {
        supportErrorTitle: 'Etwas ist schiefgelaufen..(',
    },
    validations: {
        password: 'Mindestens 8 Zeichen',
        email: 'Email eingeben',
        phone: 'Geben Sie Ihr Telefon ein',
        required: 'Pflichtfeld',
        repeatPassword: 'Passwörter müssen übereinstimmen',
    },
    afterRegistration: {
        title: 'Wir melden uns bald!',
        text: 'Wir haben Ihre Bewerbung erhalten, unser Manager wird die Angaben prüfen und sich mit Ihnen in Verbindung setzen, um weitere Daten zu klären. Es dauert nicht länger als 2 Stunden während der Arbeitszeit von 10:00 bis 20:00 Uhr.',
    },

    afterSupport: {
        title: 'Wir melden uns bald!',
        text: 'Alles super! Wir haben Ihre Nachricht erhalten und jemand aus unserem Team wird sich in Kürze mit Ihnen in Verbindung setzen. Inzwischen haben wir die Daten zu Ihrer Bewerbung an die angegebene E-Mail gesendet.',
        footerLink1: 'Für Quests einloggen',
        footerText: 'oder',
        footerLink2: 'Kundenlogin',
    },

    authVisitors: {
        label: 'Login für Spieler',
        title: 'Können wir ein paar Rätsel lösen? ',
        emailLabel: 'Telefon / E-Mail',
        passwordLabel: 'Passwort',
        checkbox: 'der Computer eines anderen',
        removeLink: 'Passwort vergessen?',
        button: 'Melden Sie sich bei Ihrem Konto an',
        text1: 'Sie haben noch kein Konto? Sie können',
        textLink1: 'Jetzt registrieren',
        text2: '. Wenn Sie der Besitzer oder Mitarbeiter der Quest sind, gehen Sie zu',
        textLink2: 'Quest-Autorisierung',
    },

    authGirls: {
        label: 'Quest-Autorisierung',
        title: 'Wir freuen uns, dass Sie wieder da sind. ',
        emailLabel: 'Telefon / E-Mail',
        passwordLabel: 'Passwort',
        checkbox: 'der Computer eines anderen',
        removeLink: 'Passwort vergessen?',
        button: 'Melden Sie sich bei Ihrem Konto an',
        text: 'Sie haben noch kein Konto?',
        textLink: 'Jetzt registrieren',
    },

    passwordChange: {
        label: 'Für Quests',
        title: 'Passwort Wiederherstellung',
        description:
            'Legen Sie ein neues Passwort fest. Die Mindestlänge beträgt 8 Zeichen, mindestens ein Buchstabe ist erforderlich.',
        emailLabel: 'Neues Passwort',
        passwordLabel: 'Wiederholen Sie das Passwort',
        button: 'Melden Sie sich bei Ihrem Konto an',
        text1: 'Sie können Ihr Passwort nicht wiederherstellen?',
        textLink1: 'Technischen Support kontaktieren',
        text2: 'Sie haben bereits ein Konto?',
        textLink2: 'Hier anmelden',
    },

    passwordRestore: {
        label: 'Für Quests',
        title: 'Passwort Wiederherstellung',
        description:
            'Geben Sie die E-Mail-Adresse ein, die bei der Registrierung eines Kontos angegeben wurde',
        emailLabel: 'Email',
        button: 'Passwort wiederherstellen',
        text1: 'Sie können Ihr Passwort nicht wiederherstellen?',
        textLink1: 'Technischen Support kontaktieren',
        text2: 'Sie haben bereits ein Konto?',
        textLink2: 'Hier anmelden',
    },

    registrationVisitors: {
        label: 'Registrierung für Besucher',
        title: 'Willkommen in der Abenteurer-Community',
        nameLabel: 'Name',
        phoneLabel: 'Telefon',
        emailLabel: 'Email',
        button: 'Jetzt registrieren',
        text1: 'Indem Sie ein Konto erstellen, stimmen Sie zu',
        textLink1: 'Richtlinie zur Verarbeitung personenbezogener Daten',
        text2: ', annehmen',
        textLink2: 'Nutzungsbedingungen',
        text3: ' und',
        textLink3: 'Nutzungsbedingungen',
        text4: 'Sie haben bereits ein Konto?',
        textLink4: 'Hier anmelden',
    },

    registrationGirls: {
        label: 'Registrierung für Salons und Privatmeister',
        title: 'Willkommen in der Voyager-Community',
        nameLabel: 'Name',
        phoneLabel: 'Telefon',
        emailLabel: 'Email',
        linkLabel: 'Link zur Website oder FB',
        button: 'Jetzt registrieren',
        text1: 'Indem Sie ein Konto erstellen, stimmen Sie zu ',
        textLink1: 'Datenschutz-Bestimmungen',
        text2: ', annehmen ',
        textLink2: 'Nutzungsbedingungen',
        text3: ' und',
        textLink3: 'Angebotsvereinbarung',
        text4: 'Sie haben bereits ein Konto?',
        textLink4: 'Hier anmelden',
    },

    acceptInvite: {
        label: 'Daten überprüfen',
        title: 'Registrierung der Voyager-Community abschließen',
        description:
            'Passwort festlegen. Die Mindestlänge beträgt 8 Zeichen, mindestens ein Buchstabe ist erforderlich.',
        button: 'Registrierung abschließen',
        emailLabel: 'Passwort erstellen',
        passwordLabel: 'Passwort wiederholen',
    },

    support: {
        title: 'Kontakt zum technischen Support',
        description:
            'Unser technischer Support-Service arbeitet rund um die Uhr. Wir werden versuchen, das Problem so schnell wie möglich zu lösen.',
        emailLabel: 'E-Mail für Feedback',
        phoneLabel: 'Telefon (wir antworten schneller)',
        problemLabel: 'Beschreiben Sie Ihr Problem',
        button: 'Senden',
        textLink1: 'Für Quests einloggen',
        text1: ' oder',
        textLink2: 'Kundenlogin',
    },

    error: {
        oops: 'Ups!...',
        Bezeichnung:
            'Der Link zum Ändern des Passworts ist ungültig. Sie können einen neuen Link erhalten, indem Sie auf die Schaltfläche unten klicken.',
        Schaltfläche: 'Neuen Link erhalten',
    },
    notFound: {
        oops: 'Ups!...',
        returnToMain: 'Zurück zum Haupt',
        noPage: 'Anscheinend existiert diese Seite nicht',
    },
};
