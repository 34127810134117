import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Typography, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { serializeRecord } from '@lovelyvoyager/services/dist/utils';
import { CreateRegistrationRequestDto } from '@lovelyvoyager/types/dist/registration-request/create-registration-request.dto';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import successImage from 'src/images/congrats.png';
import image from 'src/images/person_hi.png';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';
import { getLinks } from 'src/utils/get-links';

import styles from 'src/pages/index.module.css';

export const RegistrationGirls: React.FC<DefaultPageProps> = ({ t, lang }) => {
    const links = getLinks(lang);

    const { registrationRequests } = useApi();

    const {
        createError, createFetch, createStatusOk, createLoading,
    } = useApiMethod(
        registrationRequests.create,
        {},
    );

    if (createStatusOk) {
        return (
            <WelcomeContainer image={ successImage }>
                <Head title={ t('afterRegistration.title') } subtitle={ t('afterRegistration.text') } />
            </WelcomeContainer>
        );
    }

    return (
        <WelcomeContainer image={ image }>
            <Head label={ t('registrationGirls.label') } title={ t('registrationGirls.title') } />

            <AuthForm
                initialValues={
                    serializeRecord(
                        CreateRegistrationRequestDto,
                        {},
                    ) as unknown as CreateRegistrationRequestDto
                }
                onSubmit={ (data) => createFetch({ data }) }
                buttonText={ t('registrationGirls.button') }
                error={ createError && createError.message }
                loading={ createLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.name }
                            onChange={ handleChange('name') }
                            className={ styles.input }
                            error={ touched.name && errors.name }
                            label={ t('registrationGirls.nameLabel') }
                            required={ true }
                            dataTestId="name"
                        />
                        <Input
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            className={ styles.input }
                            error={ touched.phone && errors.phone }
                            label={ t('registrationGirls.phoneLabel') }
                            required={ true }
                            dataTestId="phone"
                        />
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            className={ styles.input }
                            error={ touched.email && errors.email }
                            label={ t('registrationGirls.emailLabel') }
                            required={ true }
                            dataTestId="email"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="primary-small" color="tertiary">
                { t('registrationGirls.text1') }
                <a href={ links.QUESTROOMS_POLICY }>{ t('registrationGirls.textLink1') }</a>
                { t('registrationGirls.text2') }
                <a href={ links.QUESTROOMS_CONDUCT }>{ t('registrationGirls.textLink2') }</a>
                { t('registrationGirls.text3') }{ ' ' }
                <a href={ links.QUESTROOMS_OFFER }>{ t('registrationGirls.textLink3') }</a> <br />
                <br />
                { t('registrationGirls.text4') }{ ' ' }
                <Link className={ styles.link_primary } to="/auth/girls">
                    { t('registrationGirls.textLink4') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
